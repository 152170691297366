<template>
<div class="home" >
    <!-- <span v-touch:swipe.left="onSwipeLeft">Swipe Left Here</span> -->
    <div class="title">Evaluation report <span>学情报告</span></div>
    <div class="title2">Hello，{{userinfo}}老师</div>
    <div class="select">
      <div class="select-item">
        <a-select
          ref="select"
          v-model:value="schoolval"
          style="width: 100%"
          @change="handleChange($event,1)"
          :options="schoolList"
        >
        </a-select>
        <img src="../assets/img/home/down.png" alt="" class="icon">
      </div>
      <div class="select-item">
        <a-select
          ref="select"
          v-model:value="levelval"
          style="width: 100%"
          @change="handleChange($event,2)"
          :options="levelList"
        >
        </a-select>
        <img src="../assets/img/home/down.png" alt="" class="icon">
      </div>
      <div class="select-item">
        <a-select
          ref="select"
          v-model:value="weekval"
          style="width: 100%"
          @change="handleChange($event,3)"
          :options="week"
        >
        </a-select>
        <img src="../assets/img/home/down.png" alt="" class="icon">
      </div>
      <div class="select-item">
        <a-select
          ref="select"
          v-model:value="timeval"
          style="width: 100%"
          @change="handleChange($event,4)"
          :options="timeList"
        >
        </a-select>
        <img src="../assets/img/home/down.png" alt="" class="icon">
      </div>
      <!-- <div class="select-item">
        <a-date-picker show-time placeholder="上课时间"
        format="YYYY-MM-DD HH:mm"
        @ok="selectDate"
        @change="onChange"
        v-model="sDate"></a-date-picker>
      </div> -->
    </div>
    <div class="student-list">
      <div class="student-item"
      v-for="(item,index) in studentlist"
      :key="index" @click="gotoPage(item)">
        <div class="head-box">
          <div class="head-img">
            <img :src="item.avatar" alt="">
          </div>
          <div class="head-state" :style="'background:'+item.color"></div>
        </div>
        <div class="name">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserName } from '../utils/auth';

export default {
  data() {
    return {
      title: '',
      userinfo: '',
      schoolval: '全部校区',
      schoolid: '',
      levelval: '全部级别',
      levelid: '',
      weekid: '',
      weekval: '选择星期',
      timeid: '',
      timeval: '上课时间',
      schoolList: [],
      levelList: [],
      studentlist: [],
      timeList: [],
      sDate: '',
      week: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '1',
          label: '星期一',
        },
        {
          value: '2',
          label: '星期二',
        },
        {
          value: '3',
          label: '星期三',
        },
        {
          value: '4',
          label: '星期四',
        },
        {
          value: '5',
          label: '星期五',
        },
        {
          value: '6',
          label: '星期六',
        },
        {
          value: '0',
          label: '星期日',
        },
      ],
      province: '',
      name: '13910962896',
      pwd: '123456',
      issend: false,
    };
  },
  created() {
    this.userinfo = getUserName();
    this.getschool();
    this.getLevel();
    this.getStudent();
    this.getUpTimeByWeek();
  },
  mounted() {

  },
  methods: {
    onChange(value, dateString) {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      this.changeTime = dateString;
      if (!dateString) {
        this.sDate = this.changeTime;
        this.getStudent();
      }
    },
    selectDate() {
      this.sDate = this.changeTime;
      this.getStudent();
    },
    gotoPage(item) {
      // if (item.color !== 'red') return;
      localStorage.setItem('student', JSON.stringify(item));
      this.$router.push({
        name: 'Student',
      });
    },
    getStudent() {
      const that = this;
      this.$server.fetch('/api/study/report/teacher/student', {
        school_id: this.schoolid,
        level_id: this.levelid,
        time: this.timeid,
        week: this.weekid,
      }).then((res) => {
        if (res.code === 200) {
          that.studentlist = res.data;
        } else {
        //   that.$toast(res.message);
        }
      });
    },
    getschool() {
      const that = this;
      this.$server.fetch('/api/manage/common/getSchool', {}).then((res) => {
        if (res.code === 200) {
          that.timeList.push({
            label: '全部',
            value: '',
          });
          for (let i = 0; i < res.data.length; i += 1) {
            const item = res.data[i];
            // console.log(item);
            that.schoolList.push({
              label: item.name,
              value: item.id,
            });
          }
        } else {
        //   that.$toast(res.message);
        }
      });
    },
    getLevel() {
      const that = this;
      this.$server.fetch('/api/manage/common/getLevel', {}).then((res) => {
        if (res.code === 200) {
          that.levelList.push({
            label: '全部',
            value: '',
          });
          for (let i = 0; i < res.data.length; i += 1) {
            const item = res.data[i];
            // console.log(item);
            that.levelList.push({
              label: item.name,
              value: item.id,
            });
          }
        } else {
        //   that.$toast(res.message);
        }
      });
    },
    // 获取时间
    getUpTimeByWeek() {
      const that = this;
      this.$server.fetch('/api/study/report/getUpTimeByWeek', { week: this.weekid }).then((res) => {
        if (res.code === 200) {
          that.timeList = [];
          that.timeList.push({
            label: '全部',
            value: '',
          });
          for (let i = 0; i < res.data.length; i += 1) {
            const item = res.data[i];
            // console.log(item);
            that.timeList.push({
              label: item,
              value: item,
            });
          }
        } else {
        //   that.$toast(res.message);
        }
      });
    },
    handleChange(e, i) {
      console.log(i);
      switch (i) {
        case 1:
          this.schoolid = e;
          break;
        case 2:
          this.levelid = e;
          break;
        case 3:
          this.weekid = e;
          this.timeid = '';
          this.timeval = '上课时间';
          this.timeList = [];
          this.getUpTimeByWeek();
          break;
        case 4:
          this.timeid = e;
          break;
        default:
          break;
      }
      this.getStudent();
    },
  },
};
</script>
<style lang="less">
.home{
  padding: 0.22rem 0.31rem;
  .title{
    line-height: 0.36rem;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 0.27rem;
    span{
      font-size: 0.12rem;
      color: #66635C;
      margin-left: 0.08rem;
    }
  }
  .title2{
    text-align: left;
    font-size: 0.23rem;
    color: #333029;
    line-height: 0.32rem;
    margin-bottom: 0.23rem;
  }
  .select{
    display: flex;
  }
  .select-item{
    width: 2.17rem;
    height: 0.35rem;
    border-radius: 0.72rem;
    overflow: hidden;
    border: 1px solid #E6E4E1;
    color: #333029;
    margin-right: 0.23rem;
    position: relative;
    .ant-select,.ant-select-selector,.ant-calendar-picker{
      height: 0.35rem;
    }
    .ant-calendar-picker{
      width: 100%;
    }
    .ant-calendar-picker-input{
      height: 0.34rem;
    }
    .ant-calendar-picker-input::placeholder,.ant-select-selection-item{
      color: #333029;
    }
    .ant-select-selection-item{
      line-height: 0.33rem;
    }
    .ant-select-selector{
      padding:  0 0.16rem;
    }
    .ant-select-selection-placeholder{
      font-size: 0.14rem;
    }
    .ant-select-suffix{
      display: none;
    }
    .icon{
      position: absolute;
      width: 0.16rem;
      right: 0.13rem;
      top: 50%;
      margin-top: -0.08rem;

    }
  }
  .student-list{
    // display: grid;
    // grid-template-columns: repeat(6, 1.37rem);
    // grid-template-rows: repeat(6, 1.21rem);
    // grid-gap:0.16rem 0.23rem ;
    margin-top: 0.23rem;
    .student-item{
      // display: inline-grid;
      // background: #333029;
      float: left;
      width: 1.37rem;
      height: 1.21rem;
      border-radius: 0.12rem;
      border: 0.01rem solid #F7F7F7;
      margin-right: 0.16rem;
      margin-bottom: 0.23rem;
      .head-box{
        width: 0.47rem;
        height: 0.47rem;
        border-radius: 50%;
        margin: 0.16rem auto 0.23rem;
        // background: #333029;
        position: relative;
      }
      .head-img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100%;
        }
      }
      .head-state{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0.12rem;
        height: 0.12rem;
        border-radius: 50%;
        border: 0.01rem solid #fff;
        background: #FCEC56;
      }
      .name{
        font-size: 0.14rem;
        color: #333029;
        text-align: center;
        height: 0.2rem;
      }
    }
  }
}
</style>
